import { PageLoader, ProtectedRoute } from '@components'
import { Megaphone } from '@hexa-ui/icons'
import { AsideMenu } from 'components/AsideMenu/AsideMenu'
import { Namespaces } from 'i18next'

import { ElementType, LazyExoticComponent, ReactElement, Suspense, lazy } from 'react'
import { ContentWrapper } from './Routes.styles'

export type Modules = 'DASHBOARD'
export type ModulesPages =
  | Modules
  | 'ORDERS'
  | 'LIST_LAYOUTS'
  | 'LIST_POCS_AND_VENDORS'
  | 'CURATORSHIPS'
  | 'TRAINING'
  | 'LIST_POCS_ACTIVATION'
  | 'VIEW_ORDER_ITEMS'
  | 'CURATORSHIP_ITEMS'
  | 'CURATORSHIP_BY_SCORE'
  | 'CURATORSHIP_ACCOUNT'

type Route = {
  path: string
  Element: LazyExoticComponent<() => ReactElement>
  children?: Route[]
}

type RoutesProps = Record<ModulesPages, Route>

type SidebarProps = Record<Modules, { id: Namespaces; path: string; icon: ElementType }>

// PAGES
export const DashboardPage = lazy(() => import('pages/Dashboard'))
export const OrdersPage = lazy(() => import('pages/Orders'))
export const LayoutsPage = lazy(() => import('pages/Layouts'))
export const ListPocsAndVendors = lazy(() => import('pages/ListPocsAndVendors'))
export const CuratorshipPage = lazy(() => import('pages/Curatorship'))
export const TrainingPage = lazy(() => import('pages/Training'))
export const ListPocsActivationPage = lazy(() => import('pages/PocsActivation'))
export const ViewOrderItemsPage = lazy(() => import('pages/ItemsDetails'))
export const CuratorShipItems = lazy(() => import('pages/CuratorShipItems'))
export const CuratorShipByScore = lazy(() => import('pages/CuratorshipByScore'))
export const CuratorShipAccount = lazy(() => import('pages/CuratorShipAccount'))

// BASE NAME
export const BASE_NAME = 'bees-po-reader'

// PATHS
export const dashboardPath = `/${BASE_NAME}` // DASHBOARD
export const ordersPath = `/${BASE_NAME}/orders` // ORDERS
export const listLayoutsPath = `/${BASE_NAME}/list/layouts` // LIST LAYOUTS
export const listPocsAndVendorsPath = `/${BASE_NAME}/list/layouts/pocs-vendors/:id` // LIST VENDORS ASSOCIATED
export const curatorshipsPath = `/${BASE_NAME}/curatorships` // CURATORSHIPS
export const trainingPath = `/${BASE_NAME}/list/layouts/:idLayout/training/:id` // TRAINING
export const listPocActivationPath = `/${BASE_NAME}/list/pocs-activation` // LIST POCS ACTIVATIONS
export const viewOrderItemsPath = `/${BASE_NAME}/orders/view-items` // VIEW_ORDER_ITEMS
export const curatorshipItemsPath = `/${BASE_NAME}/list/curatorships/:id/items` // CURATORSHIP_ITEMS
export const curatorshipByScorePath = `/${BASE_NAME}/curatorships/items/score/:orderId` // CURATORSHIP_ACCOUNTS
export const curatorshipAccountsPath = `/${BASE_NAME}/curatorships/:curatorshipId/accounts` // CURATORSHIP_ACCOUNTS

export const Routes: RoutesProps = {
  DASHBOARD: { path: dashboardPath, Element: DashboardPage },
  ORDERS: { path: ordersPath, Element: OrdersPage },
  LIST_LAYOUTS: { path: listLayoutsPath, Element: LayoutsPage },
  LIST_POCS_AND_VENDORS: { path: listPocsAndVendorsPath, Element: ListPocsAndVendors },
  CURATORSHIPS: { path: curatorshipsPath, Element: CuratorshipPage },
  TRAINING: { path: trainingPath, Element: TrainingPage },
  LIST_POCS_ACTIVATION: { path: listPocActivationPath, Element: ListPocsActivationPage },
  VIEW_ORDER_ITEMS: { path: viewOrderItemsPath, Element: ViewOrderItemsPage },
  CURATORSHIP_BY_SCORE: { path: curatorshipByScorePath, Element: CuratorShipByScore },
  CURATORSHIP_ITEMS: { path: curatorshipItemsPath, Element: CuratorShipItems },
  CURATORSHIP_ACCOUNT: { path: curatorshipAccountsPath, Element: CuratorShipAccount },
}

export const userPermissions = {
  write: 'LinkAdmin.POReader.Write',
  all: ['LinkAdmin.POReader.Write'],
}

export const Sidebar: SidebarProps = {
  DASHBOARD: {
    id: 'common',
    path: dashboardPath,
    icon: () => <Megaphone />,
  },
}

export const sidebar = [Sidebar.DASHBOARD]

export const modules = [
  Routes.DASHBOARD,
  Routes.ORDERS,
  Routes.LIST_LAYOUTS,
  Routes.LIST_POCS_AND_VENDORS,
  Routes.CURATORSHIPS,
  Routes.TRAINING,
  Routes.LIST_POCS_ACTIVATION,
  Routes.VIEW_ORDER_ITEMS,
  Routes.CURATORSHIP_ITEMS,
  Routes.CURATORSHIP_BY_SCORE,
  Routes.CURATORSHIP_ACCOUNT,
]

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <ProtectedRoute isEnabled permission={userPermissions.all}>
        <ContentWrapper>
          <AsideMenu />
          <Element />
        </ContentWrapper>
      </ProtectedRoute>
    </Suspense>
  ),
}))
